import Cookies from 'js-cookie';
import { ReadonlyRequestCookies } from 'next/dist/server/web/spec-extension/adapters/request-cookies';

export class AuthService {
  readonly COOKIE_AUTH_TOKEN = 'authToken';
  private cookies: typeof Cookies | ReadonlyRequestCookies;

  constructor(cookies: typeof Cookies | ReadonlyRequestCookies) {
    this.cookies = cookies;
  }

  get isAuthenticated(): boolean {
    return !!this.cookies.get(this.COOKIE_AUTH_TOKEN);
  }

  get token(): string {
    return (
      (this.cookies.get(this.COOKIE_AUTH_TOKEN) as string | undefined) ?? ''
    );
  }

  setToken = (token: string, expiresInSeconds: string) => {
    const options = {
      expires: Number(expiresInSeconds) || undefined,
    };
    this.cookies.set(this.COOKIE_AUTH_TOKEN, token, options);
  };

  removeToken = () => {
    Cookies.remove(this.COOKIE_AUTH_TOKEN);
  };
}

const Auth = new AuthService(Cookies);
export default Auth;
