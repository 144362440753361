import axios from 'axios';
import { parseCookies } from 'nookies';

const DATABASE_ID = process.env.NEXT_PUBLIC_DATABASE_ID;

export const getTokenFromCookies = () => {
  const cookies = parseCookies();
  const tokenArrayString = cookies[`sb-${DATABASE_ID}-auth-token`] || '';

  if (tokenArrayString) {
    try {
      const tokenArray = JSON.parse(tokenArrayString);
      return Array.isArray(tokenArray) ? tokenArray[0] : null;
    } catch (error) {
      console.error('Failed to parse token array:', error);
      return null;
    }
  }

  return null;
};

const client = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

client.interceptors.request.use((config) => {
  const token = getTokenFromCookies();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

client.interceptors.response.use((res) => {
  return res.data;
});

export default client;
