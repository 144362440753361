import {
  InviteUserRequest,
  InviteUserResponse,
  UpdateInviteRoleRequest,
  UpdateInviteRoleResponse,
  UpdateMemberRoleRequest,
  UpdateMemberRoleResponse,
} from '@formo/shared';
import { useMutation } from '@tanstack/react-query';
import { FC, useMemo, useState } from 'react';
import { MemberListProvider } from '~/app/context/MemberListContext';
import useDashboard from '~/app/hooks/useDashboard';
import useMemberList from '~/app/hooks/useMemberList/useMemberList';
import { Button } from '~/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '~/components/ui/dialog';
import MultiInput from '~/components/ui/multi-input';
import { useToast } from '~/components/ui/use-toast';
import { TEAM_ROLES } from '~/constants/permission';
import client from '~/lib/client';

import Member, { MemberSkeleton } from '../components/Member';
import RoleSelect from '../components/RoleSelect';

const roles = [
  {
    label: 'Admin',
    value: TEAM_ROLES.ADMIN,
    subtitle: 'Manage workspace settings',
  },
  {
    label: 'Editor',
    value: TEAM_ROLES.EDITOR,
    subtitle: 'Publish and make edits ',
  },
  {
    label: 'Member',
    value: TEAM_ROLES.MEMBER,
    subtitle: 'View forms and projects',
  },
];

export type MemberListModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MemberListModal: FC<MemberListModalProps> = ({ open, setOpen }) => {
  const [role, setRole] = useState<TEAM_ROLES>(roles[0]!.value);
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  const { invites, refetchInvites, isLoadingInvite } = useMemberList();
  const { members, refetchMembers, activeTeam } = useDashboard();
  const { toast } = useToast();

  const memberEmails = useMemo(() => {
    const emails = new Set<string>();
    for (const member of members) {
      emails.add(member.user.email);
    }
    return emails;
  }, [members]);

  const inviteEmails = useMemo(() => {
    const emails = new Set<string>();
    for (const invite of invites) {
      emails.add(invite.email);
    }
    return emails;
  }, [invites]);

  const { mutateAsync: inviteUser, isPending: isPendingInvite } = useMutation<
    InviteUserResponse,
    Error,
    InviteUserRequest['body'] & InviteUserRequest['params']
  >({
    mutationFn: async ({ role, email, teamId }) =>
      (await client.post(`/api/teams/${teamId}/invites`, { role, email })).data,
  });

  const { mutateAsync: updateMemberRole, isPending: isPendingMemberRole } =
    useMutation<
      UpdateMemberRoleResponse,
      Error,
      UpdateMemberRoleRequest['body'] & UpdateMemberRoleRequest['params']
    >({
      mutationFn: async ({ role, teamId, userId }) =>
        (await client.put(`/api/teams/${teamId}/members/${userId}`, { role }))
          .data,
    });

  const { mutateAsync: updateInviteRole, isPending: isPendingInviteRole } =
    useMutation<
      UpdateInviteRoleResponse,
      Error,
      UpdateInviteRoleRequest['body'] & UpdateInviteRoleRequest['params']
    >({
      mutationFn: async ({ role, teamId, inviteId }) =>
        (
          await client.put(`/api/teams/${teamId}/invites/${inviteId}`, {
            role,
          })
        ).data,
    });

  const isPendingRole = isPendingMemberRole || isPendingInviteRole;

  const onEmailChange = (value: string) => {
    setEmail(value);
  };

  const onSubmit = async () => {
    if (!email || role === TEAM_ROLES.OWNER || !activeTeam) return;
    if (!email) {
      setError('Must provide an email address');
      return;
    }
    if (!email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)) {
      setError(`Invalid email address: '${email}'`);
      return;
    }
    if (memberEmails.has(email)) {
      setError(`User with email '${email}' is already in the team`);
      return;
    }
    if (inviteEmails.has(email)) {
      setError(`Invite with email '${email}' is already sent`);
      return;
    }
    setError(null);
    try {
      await inviteUser({
        email,
        role,
        teamId: activeTeam?.teamId,
      });
      toast({
        title: 'Invite sent',
        description: `Sent to ${email}`,
      });

      await refetchInvites();
    } catch (error: any) {
      toast({
        title: 'Failed to invite user',
        description: error.message,
      });
    } finally {
      setEmail('');
    }
  };

  const onChangeMemberRole = async (id: string, role: TEAM_ROLES) => {
    if (role === TEAM_ROLES.OWNER || !activeTeam) return;
    try {
      await updateMemberRole({
        userId: id,
        teamId: activeTeam.teamId,
        role,
      });
      toast({
        title: 'Role updated',
        description: 'Role updated successfully',
      });
      await refetchMembers();
    } catch (error: any) {
      toast({
        title: 'Failed to update role',
        description: error.message,
      });
    }
  };

  const onChangeInviteRole = async (id: string, role: TEAM_ROLES) => {
    if (role === TEAM_ROLES.OWNER || !activeTeam) return;
    try {
      await updateInviteRole({
        inviteId: id,
        teamId: activeTeam.teamId,
        role,
      });
      toast({
        title: 'Role updated',
        description: 'Role updated successfully',
      });
      await refetchInvites();
    } catch (error: any) {
      toast({
        title: 'Failed to update role',
        description: error.message,
      });
    }
  };

  return (
    <MemberListProvider>
      <Dialog
        open={open}
        onOpenChange={(value) => {
          setOpen((prev) => (prev === value ? prev : value));
        }}
      >
        <DialogContent className="max-w-3xl w-full h-[60lvh] overflow-y-auto overflow-x-clip flex flex-col p-0 gap-6 rounded-lg">
          <DialogHeader className="px-8 pt-6">
            <DialogTitle className="text-xl text-base-black font-medium">
              Team members
            </DialogTitle>
          </DialogHeader>
          <div className="px-8">
            <div className="flex gap-3 max-w-full overflow-clip">
              <div className="flex border rounded-lg flex-1 min-w-0 h-[42px] has-[input:focus]:border-primary-600 has-[input:focus]:border-1.5 overflow-clip items-center">
                <MultiInput
                  placeholder="email@example.com"
                  className="border-none w-full max-w-full h-[42px]"
                  disabled={isPendingInvite}
                  value={email}
                  onChange={onEmailChange}
                  onSubmit={onSubmit}
                />
                <RoleSelect
                  value={role}
                  onValueChange={(value: TEAM_ROLES) => setRole(value)}
                  disabled={isPendingInvite}
                  label={roles.find((r) => r.value === role)?.label}
                  roles={roles}
                />
              </div>
              <Button
                type="button"
                onClick={onSubmit}
                className="h-[42px] rounded-lg min-w-min border-1.5 border-primary-400 leading-none"
                disabled={isPendingInvite || !email}
              >
                Send invite
              </Button>
            </div>
            {error && (
              <div className="text-negative-base text-sm mt-1 font-medium">
                {error}
              </div>
            )}
          </div>
          <div className="max-w-full overflow-x-clip overflow-y-auto max-h-full">
            {members.map((member) => (
              <Member
                key={member.user.id}
                {...member.user}
                availableRoles={roles}
                role={member.role}
                onChangeRole={onChangeMemberRole}
                isPendingRole={isPendingRole}
              />
            ))}
            {isLoadingInvite
              ? [...Array(2)].map((_, i) => <MemberSkeleton key={i} />)
              : invites.map((invite) => (
                  <Member
                    key={invite.id}
                    {...invite}
                    availableRoles={roles}
                    isPendingRole={isPendingRole}
                    onChangeRole={onChangeInviteRole}
                    isInvite
                  />
                ))}
          </div>
        </DialogContent>
      </Dialog>
    </MemberListProvider>
  );
};

export default MemberListModal;
