'use client';

import { Session, User } from '@supabase/supabase-js';
import Cookies from 'js-cookie';
import { createContext, useContext, useEffect, useState } from 'react';
import { AuthService } from '~/shared/services/auth';
import { useSupabase } from '~/shared/utils/useSupabase';

const AuthContext = createContext<{
  session: Session | null | undefined;
  user: User | null | undefined;
  signOut: () => void;
}>({ session: null, user: null, signOut: () => {} });

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<User | null>();
  const [session, setSession] = useState<Session | null>();
  const [loading, setLoading] = useState(true);
  const supabase = useSupabase();
  const Auth = new AuthService(Cookies);

  useEffect(() => {
    const setData = async () => {
      try {
        const {
          data: { user },
          error,
        } = await supabase.auth.getUser();
        const {
          data: { session },
        } = await supabase.auth.getSession();

        if (error) throw error;

        setSession(session);
        setUser(user);
        setLoading(false);
        const redirect_uri = sessionStorage.getItem('redirect_uri');
        if (redirect_uri) {
          sessionStorage.removeItem('redirect_uri');
          window.location.href = redirect_uri;
        }
      } catch (error) {
        throw `Error getting user: ${error}`;
      }
    };

    const { data: listener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        setSession(session);
        setUser(session?.user);
        setLoading(false);
        const redirect_uri = sessionStorage.getItem('redirect_uri');
        if (redirect_uri) {
          sessionStorage.removeItem('redirect_uri');
          window.location.href = redirect_uri;
        }
      },
    );

    if (Auth.isAuthenticated) setData();

    return () => {
      listener?.subscription.unsubscribe();
    };
  }, []);

  const value = {
    session,
    user,
    signOut: async () => {
      await supabase.auth.signOut();
      Auth.removeToken();
    },
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
