'use client';

import { CreateTeamRequest, CreateTeamResponse } from '@formo/shared';
import { useMutation } from '@tanstack/react-query';
import { FC, useState } from 'react';
import { useAuth } from '~/app/hooks/AuthProvider';
import { Button } from '~/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '~/components/ui/dialog';
import { Input } from '~/components/ui/input';
import { useToast } from '~/components/ui/use-toast';
import client from '~/lib/client';

export type CreateTeamModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line no-unused-vars
  onSubmit?: (data: CreateTeamResponse) => void;
};

const CreateTeamModal: FC<CreateTeamModalProps> = ({
  onSubmit,
  open,
  setOpen,
}) => {
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const { user } = useAuth();
  const { toast } = useToast();

  const { mutateAsync, isPending } = useMutation<
    CreateTeamResponse,
    Error,
    CreateTeamRequest['body']
  >({
    mutationFn: async (data) => (await client.post('/api/teams', data)).data,
  });

  const handleSubmit = async () => {
    if (!name) {
      setError('Please provide a workspace name');
      return;
    }
    if (!user) {
      return;
    }

    try {
      const team = await mutateAsync({
        name: name,
        tier: 'Starter',
      });
      toast({
        title: 'Workspace created',
        description: `Workspace ${name} has been created`,
      });
      onSubmit?.(team);
      setOpen(false);
    } catch (error: any) {
      toast({
        title: 'Failed to create workspace',
        description: error.message,
      });
    }
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(value) => {
        if (isPending) return;
        setOpen((prev) => (prev === value ? prev : value));
        if (!value) {
          setName('');
          setError('');
        }
      }}
    >
      <DialogContent className="min-w-[560px] gap-0 rounded-lg">
        <DialogHeader>
          <DialogTitle className="text-xl font-medium text-base-black">
            Create a new workspace
          </DialogTitle>
        </DialogHeader>
        <div className="mt-[18px] flex flex-col gap-2">
          <Input
            type="text"
            id="teamName"
            className="input border-1.5 !ring-transparent transition-shadow focus:border-primary-600 focus:shadow-[0px_0px_4px_0px_rgba(134,219,46,1)] focus-visible:ring-transparent focus-visible:ring-offset-0"
            placeholder="My amazing team"
            value={name}
            disabled={isPending}
            onChange={(e) => {
              const value = e.target.value;
              setName(value);
              if (value) {
                setError('');
              } else {
                setError('Please provide a workspace name');
              }
            }}
          />
          {error && (
            <span className="text-sm font-medium text-negative-base">
              {error}
            </span>
          )}
        </div>
        <DialogFooter className="mt-[18px] flex justify-end gap-3">
          <Button
            onClick={handleSubmit}
            disabled={!!error || isPending}
            className="text-base-black ring-[1.5px] ring-inset ring-primary-400"
          >
            Create workspace
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateTeamModal;
