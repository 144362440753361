import { LeaveTeamRequest, LeaveTeamResponse } from '@formo/shared';
import { useMutation } from '@tanstack/react-query';
import { FC } from 'react';
import { Button } from '~/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '~/components/ui/dialog';
import { useToast } from '~/components/ui/use-toast';
import client from '~/lib/client';

export type LeaveTeamModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: () => void;
  teamId: string;
  teamName: string;
};

const LeaveTeamModal: FC<LeaveTeamModalProps> = ({
  open,
  setOpen,
  teamId,
  teamName,
  onSubmit,
}) => {
  const { toast } = useToast();
  const { mutateAsync: leaveTeam, isPending } = useMutation<
    LeaveTeamResponse,
    Error,
    LeaveTeamRequest['params']
  >({
    mutationFn: async ({ teamId: id }) =>
      (await client.post(`/api/teams/${id}/leave`)).data,
  });

  const handleSubmit = async () => {
    if (!teamId) return;
    try {
      await leaveTeam({
        teamId,
      });
      setOpen(false);
      onSubmit?.();
      toast({
        title: `You have left ${teamName}`,
      });
    } catch (error: any) {
      toast({
        title: 'Failed to leave workspace',
        description: error.message,
      });
    }
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(value) => {
        if (isPending) return;
        setOpen((prev) => (prev === value ? prev : value));
      }}
    >
      <DialogContent className="gap-0 rounded-lg">
        <DialogHeader className="space-y-2">
          <DialogTitle className="text-xl font-medium text-base-black">
            Are you sure you want to leave {teamName}?
          </DialogTitle>
          <DialogDescription className="text-base font-medium text-base-dark-100">
            You will lose access to all forms and data in this workspace. This
            action is irreversible.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="mt-[18px] flex justify-end gap-3">
          <Button
            variant="ghost"
            disabled={isPending}
            onClick={() => setOpen(false)}
            className="text-base text-base-dark-400"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={isPending}
            className="text-base text-white ring-[1.5px] ring-inset ring-white/30"
            variant="destructive"
          >
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default LeaveTeamModal;
