'use client';

import { FC } from 'react';
import { useAuth } from '~/app/hooks/AuthProvider';
import useDashboard from '~/app/hooks/useDashboard';
import useMemberList from '~/app/hooks/useMemberList/useMemberList';
import { Dialog, DialogContent } from '~/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~/components/ui/popover';
import { TEAM_ACTIONS, TEAM_ROLES } from '~/constants/permission';

import { VerticalEllipsis } from '../../common';
import ActionModal from './ActionModal';
import ActionPopover, { PopoverArrow } from './ActionPopover';
import RoleSelect, { RoleOption } from './RoleSelect';

type MemberProps = {
  id: string;
  email: string;
  role: string;
  isPendingRole?: boolean;
  onChangeRole?: (userId: string, role: TEAM_ROLES) => void;
  availableRoles: RoleOption[];
  isInvite?: boolean;
};

const Member: FC<MemberProps> = ({
  id,
  email,
  role,
  isPendingRole = false,
  onChangeRole,
  availableRoles,
  isInvite = false,
}) => {
  const { user: currentUser } = useAuth();
  const { permissions, activeTeam } = useDashboard();

  const {
    onResendInvite,
    setPopup,
    isLoading,
    onRemoveMember,
    onCancelInvite,
    shouldShowPopup,
    onTransferOwnership,
  } = useMemberList();
  const isPopoverOpen = shouldShowPopup(id, 'popover');

  return (
    <>
      <Popover
        modal={true}
        open={isPopoverOpen}
        onOpenChange={(value) => {
          if (!value) setPopup(null);
        }}
      >
        <div className="flex items-center border-t border-black/5 py-3 pl-8 pr-2">
          <div className="flex aspect-square h-[36px] items-center justify-center rounded-full border border-[rgba(112,125,98,0.2)] bg-base-black bg-[linear-gradient(147.01deg,_rgba(167,_229,_103,_0)_21.38%,_rgba(167,_229,_103,_0.3)_82.59%),linear-gradient(225.46deg,_rgba(167,_229,_103,_0)_54.87%,_rgba(199,_254,_141,_0.1)_84.18%)] text-base text-white">
            {email?.[0]?.toUpperCase()}
          </div>
          <div className="ml-2.5 min-w-0">
            <div className="flex gap-1.5">
              <span className="min-w-0 overflow-clip text-ellipsis text-base font-normal text-base-dark-300">
                {email}
              </span>
              {id === currentUser?.id && (
                <span className="flex h-5 items-center justify-center rounded-10 bg-neutral-100-2 px-2.5 py-0.5 text-xs font-medium text-primary-800">
                  You
                </span>
              )}
            </div>
          </div>
          <RoleSelect
            onValueChange={(value: TEAM_ROLES) => onChangeRole?.(id, value)}
            value={role}
            disabled={
              id === currentUser?.id ||
              role === TEAM_ROLES.OWNER ||
              !permissions.includes(TEAM_ACTIONS.MEMBERS_MANAGEMENT) ||
              isPendingRole
            }
            label={
              role === TEAM_ROLES.OWNER
                ? 'Owner'
                : availableRoles.find((r) => r.value === role)?.label
            }
            roles={availableRoles}
            classNames={{
              trigger:
                'ml-auto w-[104px] border justify-between border-solid flex-none h-[34px]',
            }}
          />
          {isInvite && (
            <div className="ml-2.5 flex items-center self-stretch text-nowrap rounded-lg bg-base-dark-10 px-[9px] py-1.5 text-xs text-neutral-700">
              Invite pending
            </div>
          )}
          {/* 
            Only show the vertical ellipsis icon if the user has the permission to manage members, not the owner of the team, and the member is not the current user
           */}
          {permissions.includes(TEAM_ACTIONS.MEMBERS_MANAGEMENT) &&
          id !== currentUser?.id &&
          role !== TEAM_ROLES.OWNER ? (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <PopoverTrigger>
                  <VerticalEllipsis
                    size={20}
                    color="rgba(142,142,146)"
                    className="ml-1 flex-shrink-0 cursor-pointer transition-colors hover:text-primary-900"
                  />
                </PopoverTrigger>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                align="end"
                sideOffset={12}
                className="border-[0.5px] border-black/10 p-0"
              >
                {isInvite ? (
                  <>
                    <DropdownMenuItem
                      className="cursor-pointer rounded-none px-[14px] py-2.5"
                      onClick={() => onResendInvite(id, email)}
                      disabled={isLoading}
                    >
                      Resend
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      className="cursor-pointer rounded-none px-[14px] py-2.5"
                      variant="destructive"
                      onClick={() =>
                        setPopup({
                          id,
                          mode: 'cancel',
                          type: 'popover',
                        })
                      }
                      disabled={isLoading}
                    >
                      Cancel
                    </DropdownMenuItem>
                  </>
                ) : (
                  <>
                    {permissions.includes(TEAM_ACTIONS.TRANSFER_OWNERSHIP) && (
                      <DropdownMenuItem
                        className="cursor-pointer rounded-none px-[14px] py-2.5"
                        disabled={isLoading}
                        onClick={() => {
                          setPopup({
                            id,
                            mode: 'transfer',
                            type: 'modal',
                          });
                        }}
                      >
                        Transfer ownership
                      </DropdownMenuItem>
                    )}
                    {role !== TEAM_ROLES.OWNER && (
                      <DropdownMenuItem
                        className="cursor-pointer rounded-none px-[14px] py-2.5"
                        variant="destructive"
                        onClick={() =>
                          setPopup({
                            id,
                            mode: 'remove',
                            type: 'popover',
                          })
                        }
                        disabled={isLoading}
                      >
                        Remove member
                      </DropdownMenuItem>
                    )}
                  </>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          ) : (
            <div className="ml-1 aspect-auto w-5 flex-shrink-0"></div>
          )}
        </div>
        {/* 24px is the vertical ellipsis icon (20px) + ml-1 (4px); any number higher than this does nothing */}
        <PopoverContent
          align="end"
          sideOffset={12}
          alignOffset={-4}
          className="max-w-[300px] border-1 border-black/5 p-3"
        >
          <PopoverArrow open={isPopoverOpen} />
          {shouldShowPopup(id, 'popover', 'remove') && (
            <ActionPopover
              variant="destructive"
              title="Remove member"
              description={`${email} will no longer be able to access your workspace`}
              onCancel={() => {
                setPopup(null);
              }}
              onConfirm={() => onRemoveMember(id, email)}
              confirmText="Remove"
            />
          )}
          {shouldShowPopup(id, 'popover', 'cancel') && (
            <ActionPopover
              variant="destructive"
              title="Cancel invite?"
              onCancel={() => {
                setPopup(null);
              }}
              onConfirm={() => onCancelInvite(id, email)}
              confirmText="Yes, cancel"
              cancelText="No"
            />
          )}
        </PopoverContent>
      </Popover>
      <Dialog
        open={shouldShowPopup(id, 'modal')}
        onOpenChange={(value) => {
          if (!value) setPopup(null);
        }}
      >
        <DialogContent className="max-w-3xl gap-0 rounded-lg">
          {shouldShowPopup(id, 'modal', 'transfer') && (
            <ActionModal
              title="Transfer ownership"
              description={
                <>
                  Certain that you want to make{' '}
                  <span className="text-base-dark-400">{email}</span> the owner
                  of {activeTeam?.team.teamName}? You cannot undo this action
                </>
              }
              confirmText="Transfer ownership"
              onConfirm={() => onTransferOwnership(id, email)}
              cancelText="Cancel"
              onCancel={() => setPopup(null)}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export const MemberSkeleton = () => {
  return (
    <div className="flex items-center border-t border-black/5 py-3 pl-8 pr-2">
      <div className="aspect-square h-8 animate-pulse rounded-full border border-[rgba(112,125,98,0.2)] bg-base-black/20"></div>
      <div className="ml-2.5 h-6 w-52 animate-pulse rounded-md bg-base-black/20"></div>
      <RoleSelect
        disabled
        roles={[]}
        classNames={{
          trigger:
            'ml-auto w-max border border-solid flex-none h-[34px] w-20 text-transparent animate-pulse bg-base-black/20',
        }}
      />
      <div className="ml-1 aspect-auto w-5 flex-shrink-0"></div>
    </div>
  );
};

export default Member;
