'use client';

import {
  ChangeEvent,
  InputHTMLAttributes,
  KeyboardEvent,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { cn, mergeRefs } from '~/lib/utils';

import { ScrollArea, ScrollBar } from './scroll-area';

export type MultiInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'onChange'
> & {
  value?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  delimiter?: string[];
  allowDuplicates?: boolean;
  classNames?: {
    root?: string;
    input?: string;
    tag?: string;
    scrollArea?: string;
  };
  onSubmit: () => Promise<void>;
};

const MultiInput = forwardRef<HTMLInputElement, MultiInputProps>(
  (
    {
      value = '',
      defaultValue,
      className,
      onChange,
      classNames,
      onSubmit,
      ...props
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [internalValue, setInternalValue] = useState<string>(
      value || defaultValue || '',
    );

    const onNewItem = useCallback(
      (item: string) => {
        setInternalValue(item);
        onChange?.(item);
        // Wait for the item to be added to the list
        setTimeout(() => {
          // Scroll to the end of the container
          if (!inputRef.current) return;
          inputRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          });
        }, 0);
      },
      [internalValue, onChange],
    );

    const onChangeHandler = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        const temp = value.trim();
        if (temp) {
          onNewItem(temp);
        }
      },
      [onNewItem],
    );

    const onKeyDown = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          onSubmit();
        }
      },
      [onSubmit, onChange, internalValue],
    );

    const onPaste = useCallback(
      (e: React.ClipboardEvent<HTMLInputElement>) => {
        const { clipboardData } = e;
        const text = clipboardData.getData('text');
        const items = text.split(/,|\s/).filter((item) => item.trim());
        items.forEach((item) => onNewItem(item));
        e.preventDefault();
      },
      [onNewItem],
    );

    useEffect(() => {
      if (value) {
        setInternalValue(value);
      }
    }, [value]);

    return (
      <ScrollArea
        className={cn('relative h-[inherit] w-full', classNames?.scrollArea)}
      >
        <div
          className={cn(
            'flex items-center gap-1.5 overflow-x-auto overflow-y-clip rounded-lg border px-1.5',
            classNames?.root,
            className,
          )}
        >
          <input
            ref={mergeRefs(ref, inputRef)}
            className={cn(
              'w-full relative border-none px-1.5 py-0 !outline-none ring-0 !ring-transparent',
              classNames?.input,
            )}
            value={value}
            onChange={onChangeHandler}
            onKeyDown={onKeyDown}
            onPaste={onPaste}
            {...props}
          />
        </div>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
    );
  },
);

export default MultiInput;
