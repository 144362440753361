'use client';

import {
  CancelInviteRequest,
  CancelInviteResponse,
  GetTeamInvitesResponse,
  RemoveTeamMemberRequest,
  RemoveTeamMemberResponse,
  ResendInviteRequest,
  ResendInviteResponse,
  TransferOwnershipRequest,
  TransferOwnershipResponse,
} from '@formo/shared';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import { FC, ReactNode, createContext, useCallback, useState } from 'react';
import { useToast } from '~/components/ui/use-toast';
import { TEAM_INVITE_STATUS } from '~/constants/status';
import client from '~/lib/client';

import useDashboard from '../hooks/useDashboard';

type Popup = {
  id: string;
  mode: 'remove' | 'cancel' | 'transfer';
  type: 'popover' | 'modal';
} | null;

type MemberListContextType = {
  isLoading: boolean;
  // Popover
  popup: Popup;
  setPopup: (value: Popup) => void;
  shouldShowPopup: (
    id: string,
    type?: NonNullable<Popup>['type'],
    mode?: NonNullable<Popup>['mode'],
  ) => boolean;
  // Member
  onRemoveMember: (userId: string, email: string) => Promise<void>;
  onTransferOwnership: (userId: string, email: string) => Promise<void>;
  // Invite
  invites: GetTeamInvitesResponse;
  isLoadingInvite: boolean;
  refetchInvites: () => Promise<any>;
  onResendInvite: (inviteId: string, email: string) => Promise<void>;
  onCancelInvite: (inviteId: string, email: string) => Promise<void>;
};

export const MemberListContext = createContext<MemberListContextType>({
  popup: null,
  setPopup: () => {},
  isLoading: false,
  shouldShowPopup: () => false,
  // Member
  onRemoveMember: async () => {},
  onTransferOwnership: async () => {},
  // Invite
  invites: [],
  isLoadingInvite: true,
  refetchInvites: async () => [],
  onResendInvite: async () => {},
  onCancelInvite: async () => {},
});

type MemberListProviderProps = {
  children: ReactNode;
};

export const MemberListProvider: FC<MemberListProviderProps> = ({
  children,
}) => {
  const { activeTeam, refetchMembers } = useDashboard();
  const { toast } = useToast();
  const router = useRouter();

  const [popup, setPopup] = useState<Popup>(null);

  const {
    data: invites = [],
    refetch: refetchInvites,
    isLoading: isLoadingInvite,
  } = useQuery<GetTeamInvitesResponse>({
    queryKey: [
      'teams',
      'invites',
      activeTeam?.teamId,
      [TEAM_INVITE_STATUS.PENDING],
    ],
    queryFn: async () =>
      (
        await client.get(
          `/api/teams/${activeTeam?.teamId}/invites?status=${TEAM_INVITE_STATUS.PENDING}`,
        )
      ).data,
    enabled: !!activeTeam?.teamId,
    refetchOnWindowFocus: false,
  });

  const { mutateAsync: resendInvite, isPending: isPendingResend } = useMutation<
    ResendInviteResponse,
    Error,
    ResendInviteRequest['params']
  >({
    mutationFn: async ({ teamId, inviteId }) =>
      (await client.post(`/api/teams/${teamId}/invites/${inviteId}`)).data,
  });

  const { mutateAsync: removeMember, isPending: isPendingRemove } = useMutation<
    RemoveTeamMemberResponse,
    Error,
    RemoveTeamMemberRequest['params']
  >({
    mutationFn: async ({ teamId, userId }) =>
      (await client.delete(`/api/teams/${teamId}/members/${userId}`)).data,
  });

  const { mutateAsync: cancelInvite, isPending: isPendingCancel } = useMutation<
    CancelInviteResponse,
    Error,
    CancelInviteRequest['params']
  >({
    mutationFn: async ({ teamId, inviteId }) =>
      (await client.delete(`/api/teams/${teamId}/invites/${inviteId}`)).data,
  });

  const { mutateAsync: transferOwnership, isPending: isPendingTransfer } =
    useMutation<
      TransferOwnershipResponse,
      Error,
      TransferOwnershipRequest['body'] & TransferOwnershipRequest['params']
    >({
      mutationFn: async ({ teamId, userId }) =>
        (
          await client.post(`/api/teams/${teamId}/transfer`, {
            userId,
          })
        ).data,
    });

  const onRemoveMember = useCallback(
    async (userId: string, email: string) => {
      if (!activeTeam) return;
      setPopup(null);
      try {
        await removeMember({
          teamId: activeTeam.teamId,
          userId: userId,
        });
        toast({
          title: 'Member removed',
          description: `${email} has been removed from the workspace`,
        });
        await refetchMembers();
      } catch (error: any) {
        toast({
          title: 'Failed to remove member',
          description: error.message,
        });
      }
    },
    [activeTeam, removeMember, refetchMembers],
  );

  const onResendInvite = useCallback(
    async (inviteId: string, email: string) => {
      if (!activeTeam) return;
      try {
        await resendInvite({
          inviteId: inviteId,
          teamId: activeTeam.teamId,
        });
        toast({
          title: 'Invite resent',
          description: `Invite resent to ${email}`,
        });
      } catch (error: any) {
        toast({
          title: 'Failed to resend invite',
          description: error.message,
        });
      }
    },
    [activeTeam, resendInvite],
  );

  const onCancelInvite = useCallback(
    async (inviteId: string, email: string) => {
      if (!activeTeam) return;
      setPopup(null);
      try {
        await cancelInvite({
          inviteId: inviteId,
          teamId: activeTeam.teamId,
        });
        toast({
          title: 'Invite canceled',
          description: `Invite to ${email} has been canceled`,
        });
        await refetchInvites();
      } catch (error: any) {
        toast({
          title: 'Failed to cancel invite',
          description: error.message,
        });
      }
    },
    [activeTeam, cancelInvite],
  );

  const onTransferOwnership = useCallback(
    async (userId: string, email: string) => {
      if (!activeTeam) return;
      setPopup(null);
      try {
        await transferOwnership({
          teamId: activeTeam.teamId,
          userId: userId,
        });
        toast({
          title: 'Ownership transferred',
          description: `Ownership has been transferred to ${email}`,
        });
        await refetchMembers();
        router.refresh();
      } catch (error: any) {
        toast({
          title: 'Failed to transfer ownership',
          description: error.message,
        });
      }
    },
    [activeTeam, refetchMembers],
  );

  const shouldShowPopup = useCallback(
    (
      id: string,
      type?: NonNullable<Popup>['type'],
      mode?: NonNullable<Popup>['mode'],
    ) => {
      if (!popup) return false;
      if (popup.id !== id) return false;
      if (type && popup.type !== type) return false;
      if (mode && popup.mode !== mode) return false;
      return true;
    },
    [popup],
  );

  return (
    <MemberListContext.Provider
      value={{
        popup: popup,
        setPopup: setPopup,
        isLoading:
          isPendingResend ||
          isPendingRemove ||
          isPendingCancel ||
          isPendingTransfer,
        shouldShowPopup,
        // Member
        onRemoveMember,
        onTransferOwnership,
        // Invite
        invites,
        isLoadingInvite: isLoadingInvite,
        refetchInvites,
        onResendInvite,
        onCancelInvite,
      }}
    >
      {children}
    </MemberListContext.Provider>
  );
};
