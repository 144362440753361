import { SelectProps } from '@radix-ui/react-select';
import { Check } from 'lucide-react';
import { FC } from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '~/components/ui/select';
import { TEAM_ROLES } from '~/constants/permission';
import { cn } from '~/lib/utils';

export type RoleOption = {
  label: string;
  value: TEAM_ROLES;
  subtitle: string;
};

type RoleSelectProps = SelectProps & {
  classNames?: {
    trigger?: string;
    content?: string;
    item?: string;
  };
  roles: RoleOption[];
  label?: string;
};

const RoleSelect: FC<RoleSelectProps> = ({
  classNames,
  roles,
  value: role,
  label,
  ...props
}) => {
  return (
    <Select {...props} value={role}>
      <SelectTrigger
        className={cn(
          'h-full min-w-min flex-1 gap-2 border-none focus:ring-transparent focus:ring-offset-0 focus-visible:ring-transparent focus-visible:ring-offset-0',
          classNames?.trigger,
        )}
        type="button"
      >
        {label}
      </SelectTrigger>
      <SelectContent
        className={cn(
          'relative gap-1 !rounded-10 border-none bg-base-black/85 p-0.5 px-1.5 shadow-[1px_2px_8px_0px_rgba(0,0,0,0.1)] backdrop-blur-sm',
          classNames?.content,
        )}
        align="end"
        alignOffset={-1.5}
      >
        {roles.map(({ value, label, subtitle }) => (
          <SelectItem
            key={value}
            value={value}
            className={cn(
              'cursor-pointer px-2 py-1 pr-10 transition-colors focus:bg-white/35',
              classNames?.item,
            )}
          >
            <div className="text-sm text-white">{label}</div>
            <div className="text-xs font-medium text-white/50">{subtitle}</div>
            {role === value && (
              <Check className="absolute right-2.5 top-1/2 h-5 w-5 -translate-y-1/2 text-white opacity-50" />
            )}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default RoleSelect;
